<template>
    <div class="feedback">
        <div class="feedback-form">
            <div class="form-item">
                <p class="item-label required">
                    <span>{{$t("my.feedbacklabel1")}}</span>
                    <span class="word-num">
                        {{feedbackTxt.length}}/1000
                    </span>
                </p>
                <div class="from-input">
                    <van-field
                        v-model="feedbackTxt"
                        rows="5"
                        autosize
                        type="textarea"
                        maxlength="1000"
                        class="input-rextarea"
                        :placeholder='$t("my.feedbackPlaceholder")'
                        />
                </div>
            </div>
            <div class="form-item">
                <p class="item-label">
                    <span>{{$t("my.feedbackPhoto")}}</span>
                </p>
                <div class="from-input uploadPhoto">
                    <van-uploader class="lable" accept="image/*" v-model="uploadimg" :multiple="true" :max-count="10000" :class="{'isedituoloader':isEdit}">
                        <div class="uploadbox">
                            <van-icon name="plus" />
                        </div>
                    </van-uploader>
                </div>
            </div>
            <div class="form-item">
                <p class="item-label">
                    <span>{{$t("my.contactWay")}}</span>
                </p>
                <div class="from-input uploadPhoto">
                    <van-field v-model="contactWay"   :placeholder='$t("my.contactWayPlaceholder")' />
                </div>
            </div>
        </div>
        <div class="submit-btn" :class="{'btn-active':disabledbtn}" @click="submitFun">
            {{$t("my.submit")}}
        </div>
    </div>
</template>

<script>
import { feedbackAdd } from "@/api/index";
export default {
    data () {
        return {
            feedbackTxt:"",
            contactWay:"",
            isEdit:false,
            uploadimg:[]
        }
    },
    computed: {
        disabledbtn(){
            return this.feedbackTxt;
        }      
    },
    created () {
        document.title = this.$t("my.Feedback")
    },
    methods:{
        async  uploadOss(index){
            if(!this.uploadimg[index].url){
                let result = await this.$uploadAxios.post('upload/base64-upload', {data:this.uploadimg[index].content,fileName:this.uploadimg[index].file.name});
                if(result.errorCode == 0){
                    this.uploadimg[index].imgUrl = result.data;
                }
            }
            if(index < (this.uploadimg.length-1)){
                index++;
                await this.uploadOss(index)
            }
        },
        async submitFun(){
            if(this.disabledbtn){
                const toast = this.$Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: this.$t("message.loadingTxt")
                });
                let datadd = {
                    postText:this.feedbackTxt
                };
                if(this.contactWay){
                    datadd.postUserTel = this.contactWay;
                }
                if(this.uploadimg.length != 0){
                    await this.uploadOss(0);
                    let imgUrls = [];
                    for(let it in this.uploadimg){
                        imgUrls.push(this.uploadimg[it].imgUrl||this.uploadimg[it].url);
                    }
                    datadd.postImgUrls = imgUrls;
                }
                feedbackAdd(datadd).then(res=>{
                    toast.clear();
                    if(res.data.errorCode == 0){
                        this.$toast(this.$t("comonTxt.operationsuccess"))
                        this.$router.go(-1);
                    }else{
                        this.$toast(this.$t("comonTxt.operationfail"))
                    }
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
.feedback{
    padding: 0 0.6rem 0.6rem 0.6rem;
    box-sizing: border-box;
    background: #F5F6FA;
    min-height: 100%;
    .feedback-form{
        .form-item{
            margin-bottom: 0.4rem;
            .item-label{
                height: 1.8rem;
               font-size: 0.56rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                line-height: 1.8rem;
            }
            .required{
                display: flex;
                justify-content: space-between;
                align-items: center;
                span:first-child{
                    position: relative;
                    &::after{
                        position: absolute;
                        right: -0.3rem;
                        color: #ee0a24;
                        font-size: 0.58rem;
                        content: '*';

                    }
                }
                .word-num{
                    font-size: 0.56rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #000000;
                }
            }
            .from-input{
                padding: 0.4rem;
                background: #fff;
                border-radius: 0.16rem;
                .input-rextarea{
                    padding: 0;
                    line-height: 0.7rem;
                    font-size: 0.56rem;                    
                }
                .van-field{
                    font-size: 0.56rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #909399;
                    padding: 0.2rem 0 ;
                }
            }
            .uploadPhoto {
                 /deep/ .van-uploader{
                    .van-uploader__input-wrapper, .van-uploader__preview .van-image, .van-uploader__preview .van-uploader__file {
                        width: 2.8rem;
                        height: 2.8rem;
                        background: #ECEEF3;
                        border-radius: 0.2rem;
                        
                    }
                    .van-uploader__preview{
                        // &:nth-child(3n+3){
                        //     margin-right: 0;
                        // }
                        .van-uploader__preview-delete{
                            height: 0.6rem;
                            width: 0.6rem;
                            border-top-right-radius: 0.2rem;
                           .van-icon {
                               font-size: 0.6rem;
                           } 
                        }
                        .van-image {
                            // border-radius: 0.3rem;
                            overflow: hidden;
                        }
                    }
                    .van-uploader__input-wrapper{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .van-icon{
                            font-size: 1.5rem;
                            margin-right: 0;
                            color: #DDDFE6;
                        }
                    }
                }
                /deep/ .isedituoloader{
                     .van-uploader__input-wrapper{
                        display: none;
                    }
                    .van-uploader__preview-delete{
                        display: none;
                    }
                }
            }
        }
    }
    .submit-btn{
        width: 11.2rem;
        height: 2rem;
        background: #DDDFE6;
        border-radius: 1rem;
        margin: 1.2rem auto;
        line-height: 2rem;
        text-align: center;
        color: #C0C4CC;
        font-size: 0.72rem;
    }
    .btn-active{
        color: #fff;
        background: linear-gradient(0deg, #499FFF 0%, #49A5FF 52%, #49C1FF 100%);
    }
}
</style>